<template>
<main class="main" v-if="!loading">
<section class="section-box">
    <div class="page-content header-clear-small">
		<div class="row">
		<div class="col-lg-12">
		<h5 class="text-uppercase font-600 mb-2">Mes Signalements</h5>
            <div class="divider bg-orange-dark-sdw mb-3"></div>
			<div class="list-icons-round mt-40" v-if="reports && reports.length > 0">
                <div v-for="report in reports" v-bind:key="report.id" class="item-icon none-bd wow animate__ animate__fadeIn animated" data-wow-delay=".1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeIn;">
					<span class="icon-left"><img src="/assets/imgs/page/homepage8/trip.svg"></span>
                  <h4 class="text-heading-5 color-green-900"><a :href="'/signalement/'+report.id"> {{report.entity.name}}</a></h4>
					<p class="text-body-text color-gray-600 mt-5">{{report.category.name}} : {{report.description}}</p> 
					<p><strong>{{report.life.name}}</strong></p>
					<span class="color-gray d-block"><i>Date : {{ report.created_at | moment('DD/MM/YYYY')}}</i></span>
                </div>
              </div>
				<p class="mt-40" v-else><strong>Aucun signalement trouvé !</strong></p>
		</div>
		</div>
    </div>
</section>	
</main>
</template>

<script>

import AppConstants from '@/config'
export default {
  name: 'Signalements',
  data() {
    return{
		reports : [],
		loading: true,
		code: ''
	}
  },
  components: {
  },
  mounted() {
      this.signalements()
  },  
  methods: {
	getConst(key){
		const Numbers = {
				API_URL : AppConstants.API_URL,
				API_TOKEN : AppConstants.API_TOKEN,
				API_ID: AppConstants.API_ID,
		};
        return Numbers[key];	
	},   
	signalements() {
				var token = this.getConst('API_TOKEN');
				var id = this.getConst('API_ID');
				var apiUrl = this.getConst('API_URL')+"user-reports"	
				var api_token = localStorage.getItem('token')
				let axios = require('axios');
				this.loading = true
				if (api_token) {
					var apiData = {
					"token" : token,
					"appid": id
					}
					axios.post(apiUrl, apiData,
					{
						headers: {
							'Authorization': 'Bearer '+ api_token,
						}
					})
					.then(response => {
						this.reports = response.data.data
						//this.code = this.reports.code
						//this.$emit('newcode', this.code)
						this.loading = false
					})
					.catch(error => {
						console.log(error.response.data.errors)
						this.loading = true
					})
					}				
	},	
	deleteSignal(id){
		var token = localStorage.getItem('token');
		this.axios.delete( this.ypodoApiUrl + '/reports/'+id,
        {
            headers: {
				'Authorization': 'Bearer '+ token
            }
          }
        )
		.then(function() {
						this.signalements()
						
		})		
        .catch(function(){
          console.log('FAILURE!!');
        });	
	},
  }
}


</script>
